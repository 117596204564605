
import Vue from 'vue'
import Component from "vue-class-component";
import { Prop } from 'vue-property-decorator';

import { CompanyDashboardApi } from '../companyDashboardAPI/CompanyDashboardAPI';

import { Branch } from '@/companyDashboardAPI/Branch'

import MainCard from '@/components/MainCard.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'

@Component({
  components: {
    MainCard: MainCard,
    LoadingSpinner: LoadingSpinner,
  }
})
export default class BranchesView extends Vue {
  @Prop({required: true})
  public readonly ApiClient!: CompanyDashboardApi;

  private cardTitle = this.$vuetify.lang.t(`$vuetify.menu_branchLink`);

  public loadingText = this.$vuetify.lang.t(`$vuetify.loadingPleaseWait`);
  public branches: Branch[] = []

  private async mounted() {
    this.cardTitle = this.$vuetify.lang.t(`$vuetify.menu_branchLink`);

    for await (const branches of this.ApiClient.getBranches()) {
      this.branches = branches
    }

    this.loadingText = ''
  }

  public getCardTitle() : string {
    return this.cardTitle
  }

  public editBranch(branch: Branch) : void {
    this.$router.push({
      name: 'BranchEdit',
      params: {
        branchData: JSON.stringify(branch)
      }
    })
  }
}
