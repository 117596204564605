
import Vue from "vue"
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class LoadingSpinner extends Vue {
    @Prop({required: true})
    public readonly loadingText!: string;
}
